import React from "react";
import classes from "./ExcelIcon.module.css";

export default function ExcelIcon({ children }) {
  return (
    // <span className={classes.excelIcon} id={id}>
    //   <img
    //     id={id}
    //     src={convertSortTypeToSrc(sortType)}
    //     onClick={sortHandler}
    //   ></img>
    // </span>

    <span className={classes.excelIcon}>
      <img src="excel_icon.svg" alt="excel_icon"></img> {children}
    </span>
  );
}
