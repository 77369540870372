import React from "react";

import CardTitle from "./CardTitle";

import DonutChart from "../chart/DonutChart";
import classes from "./OverallStatusCard.module.css";

export default function OverallStatusCard({ householdStatus }) {
  // console.log(`householdStatus=${JSON.stringify(householdStatus)}`);
  return (
    <div className={classes.card}>
      <CardTitle>전체현황</CardTitle>
      <p className={classes.totalHousehold}>
        총{" "}
        {householdStatus
          ? householdStatus.normal + householdStatus.abnormal
          : null}
        세대
      </p>
      <div className={classes.donutChartContainer}>
        {householdStatus ? (
          <div>
            <DonutChart
              width={110}
              height={90}
              chartId="statusDonutChart"
              data={[householdStatus.normal, householdStatus.abnormal]}
              labels={["정상", "AS 필요"]}
            />
          </div>
        ) : null}
      </div>

      <div className={classes.statusLegendContainer}>
        <div className={classes.normalDot}></div>
        <div> 정상</div>
        <div className={classes.statusLegendVal}>
          {householdStatus ? householdStatus.normal : null}세대
        </div>
      </div>
      <div className={classes.statusLegendContainer}>
        <div className={classes.asDot}></div>
        <div> AS 필요</div>
        <div className={classes.statusLegendVal}>
          {householdStatus ? householdStatus.abnormal : null}세대
        </div>
      </div>
    </div>
  );
}
