import React from "react";
import styled from "styled-components";

const StyledMeasureText = styled.p`
  font-weight: bolder;
  font-size: 2em;
  color: #1f1f1f;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
`;

export default function MeasureText({ children }) {
  return <StyledMeasureText>{children}</StyledMeasureText>;
}
