import { useAppContext } from "../libs/contextLib";
import React from "react";
import { Auth } from "aws-amplify";
import LoginCard from "../components/LoginCard";
import LoginCardTitle from "../components/LoginCardTitle";
import { useFormFields } from "../libs/hooksLib";
import "./Login.css";
import config from "../config";
import CONST from "../Const";

export default function Login() {
  const { userHasAuthenticated } = useAppContext();
  // const [isLoading, setIsLoading] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    userId: localStorage.getItem(CONST.LOCAL_STORAGE_KEYS.USERID) || "",
    password: "",
    rememberMe:
      localStorage.getItem(CONST.LOCAL_STORAGE_KEYS.REMEMBER_ME) === "true"
        ? true
        : false,
  });

  function validateForm() {
    return fields.userId.length > 0 && fields.password.length > 0;
  }

  async function handleSubmit(event) {
    event.preventDefault();
    // setIsLoading(true);
    function localSignIn(id, pw) {
      const found = CONST.LOCAL_USERS.find((user) => user.id === id);
      return found && found.pw === pw;
    }
    try {
      if (config.stage === "prod") {
        await Auth.signIn(fields.userId, fields.password);
        userHasAuthenticated(true);
      } else {
        if (localSignIn(fields.userId, fields.password)) {
          userHasAuthenticated(true);
          localStorage.setItem(CONST.LOCAL_STORAGE_KEYS.LOCAL_SESSION, true);
          localStorage.setItem(
            CONST.LOCAL_STORAGE_KEYS.LOCAL_USERNAME,
            fields.userId.slice()
          );
        } else {
          throw Error();
        }
      }
      localStorage.setItem(
        CONST.LOCAL_STORAGE_KEYS.REMEMBER_ME,
        fields.rememberMe
      );
      localStorage.setItem(
        CONST.LOCAL_STORAGE_KEYS.USERID,
        fields.rememberMe ? fields.userId : ""
      );
    } catch (e) {
      alert("비밀번호 오류");
      // setIsLoading(false);
    }
  }

  return (
    <div className="login-container">
      <LoginCard>
        <LoginCardTitle>고양시 미니 태양광 모니터링</LoginCardTitle>
        <form className="login-form-container" onSubmit={handleSubmit}>
          <label className="login-label">
            <b>아이디</b>
          </label>
          <input
            className="login-form"
            autoFocus
            id="userId"
            type="text"
            placeholder="아이디를 입력하세요"
            value={fields.userId}
            onChange={handleFieldChange}
          />
          <label className="login-label">
            <b>비밀번호</b>
          </label>
          <input
            className="login-form"
            id="password"
            type="password"
            placeholder="비밀번호를 입력하세요"
            value={fields.password}
            onChange={handleFieldChange}
            required
          />

          <div className="checkbox-container">
            <input
              id="rememberMe"
              type="checkbox"
              checked={fields.rememberMe}
              onChange={handleFieldChange}
            />
            <label htmlFor="rememberMe">로그인 정보 기억하기</label>
          </div>
          <button
            className="login-form button"
            type="submit"
            disabled={!validateForm()}
          >
            로그인
          </button>
        </form>
        <div className="login-form-footer"> 문의(주) 에너닷 1644-4073</div>
      </LoginCard>
    </div>
  );
}
