import React from "react";
import classes from "./AbnormalHouseholdTable.module.css";

const ABNORMAL_HOUSEHOLD_ADDR_HEADER = "abnormalHouseholdAddr";
const PLUG_STATUS = "plugStatus";
const AS_COMPANY = "dailySum";
export default function AbnormalHouseholdTable({ abnormalHouseholds }) {
  return (
    <div className={classes.abnormalHouseholdTable}>
      <table>
        <thead>
          <tr key="header">
            <th id={ABNORMAL_HOUSEHOLD_ADDR_HEADER}>설치 세대</th>
            <th id={PLUG_STATUS}>상태</th>
            <th id={AS_COMPANY}>AS 문의처</th>
          </tr>
        </thead>
        <tbody>
          {abnormalHouseholds
            ? abnormalHouseholds.map((abnormalHousehold) => {
                return (
                  <tr key={abnormalHousehold.householdId}>
                    <td key={ABNORMAL_HOUSEHOLD_ADDR_HEADER}>
                      {`향동 A4, ${abnormalHousehold.dong}동 ${abnormalHousehold.ho}호`}
                    </td>
                    <td key={PLUG_STATUS}>미니태양광 AS 필요</td>
                    <td key={AS_COMPANY}>솔라테라스 1566-3221</td>
                  </tr>
                );
              })
            : Array.from(Array(8), (_, i) => {
                return (
                  <tr key={`dummy${i}`}>
                    <td key={ABNORMAL_HOUSEHOLD_ADDR_HEADER}></td>
                    <td key={PLUG_STATUS}></td>
                    <td key={AS_COMPANY}></td>
                  </tr>
                );
              })}
        </tbody>
      </table>
    </div>
  );
}
