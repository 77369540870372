import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import config from "../config";
import CONST from "../Const";

export default function Username() {
  const [loggedInUser, setLoggedInUser] = useState();
  async function onLoad() {
    if (config.stage === "prod") {
      setLoggedInUser((await Auth.currentUserInfo()).username);
    } else {
      setLoggedInUser(
        localStorage.getItem(CONST.LOCAL_STORAGE_KEYS.LOCAL_USERNAME)
      );
    }
  }
  useEffect(() => {
    onLoad();
  }, []);

  return (
    <>
      <div>{loggedInUser}</div>
    </>
  );
}
