import React, { useState, useEffect } from "react";
import moment from "moment";
import { apiGet } from "../libs/awsLib";
import { onError } from "../libs/errorLib";
import WeatherCard from "../components/card/WeatherCard";
import MonthlyAccOutputCard from "../components/card/MonthlyAccOuputCard";
import CO2ReductionCard from "../components/card/CO2ReductionCard";
import TreePlantingCard from "../components/card/TreePlantingCard";
import OutputForecastCard from "../components/card/OutputForecastCard";
import OverallStatusCard from "../components/card/OverallStatusCard";
import TodayHourlyOutputCard from "../components/card/TodayHourlyOutputCard";
import AsGuideCard from "../components/card/AsGuideCard";
import TodayDirectionOutputCard from "../components/card/TodayDirectionOutputCard";
import CONST from "../Const";
import "./Dashboard.css";

const Dashboard = () => {
  const [weather, setWeather] = useState();
  const [monthlyOutput, setMonthlyOutput] = useState(0);
  const [tCO2, setTCO2] = useState(0);
  const [plantingEffect, setPlantingEffect] = useState(0);
  const [outputForecast, setOutputForecast] = useState(0);
  const [householdStatus, setHouseHoldStatus] = useState();
  const [hourlyOuputs, setHourlyOuputs] = useState();
  const [directionSortedOutput, setDirectionSortedOutput] = useState();

  useEffect(() => {
    async function onLoad() {
      try {
        const dashboardData = await apiGet(
          "minisolar",
          `/dashboard/aggregation`,
          {
            yyyymmdd: moment().format("YYYYMMDD"),
          }
        );

        if (!dashboardData) {
          return;
        }

        setWeather(dashboardData.weatherData);
        setMonthlyOutput(0);
        setMonthlyOutput(
          Number((dashboardData.monthlyOutput / CONST.ONE_K).toFixed(2))
        );

        setTCO2(0);
        setTCO2(Number(dashboardData.tCO2.toFixed(3)));

        setPlantingEffect(0);
        setPlantingEffect(Number(dashboardData.plantingEffect.toFixed(2)));

        setOutputForecast(0);
        setOutputForecast(
          Number(
            (dashboardData.expectedMonthlyTotalOutput / CONST.ONE_K).toFixed(2)
          )
        );

        setHouseHoldStatus(dashboardData.householdStatus);

        setHourlyOuputs([]);
        setHourlyOuputs(dashboardData.hourlyOuputs);

        setDirectionSortedOutput([
          ...dashboardData.floorLevelAndDirectionSortedOutput,
        ]);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
    const intervalId = setInterval(() => onLoad(), 30000);
    return () => {
      // console.log(`intervalId=${intervalId}`);
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section className="dashboard">
      <div className="dashboard-1">
        <WeatherCard weather={weather}></WeatherCard>
        <MonthlyAccOutputCard monthlyOutput={monthlyOutput} />
        <CO2ReductionCard tCO2={tCO2} />
        <TreePlantingCard plantingEffect={plantingEffect} />
        <OutputForecastCard outputForecast={outputForecast} />
      </div>
      <div className="dashboard-2">
        <div className="dashboard-2-1">
          <div className="dashboard-2-1-1">
            <OverallStatusCard householdStatus={householdStatus} />
            <TodayHourlyOutputCard hourlyOuputs={hourlyOuputs} />
          </div>
          <div className="dashboard-2-1-2">
            <AsGuideCard
              abnormalHouseholds={
                householdStatus ? householdStatus.abnormalHouseholds : null
              }
            />
          </div>
        </div>
        <div className="dashboard-2-2">
          <TodayDirectionOutputCard
            directionSortedOutput={directionSortedOutput}
          />
        </div>
      </div>
    </section>
  );
};

export default Dashboard;
