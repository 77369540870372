import React, { useState, useEffect } from "react";
import Routes from "./Routes";
import { useHistory, NavLink } from "react-router-dom";
import { Auth } from "aws-amplify";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import config from "./config";
import Username from "./components/Username";
import CONST from "./Const";
import "./App.css";

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      if (config.stage === "prod") {
        await Auth.currentSession();
        userHasAuthenticated(true);
      } else {
        const localSession =
          localStorage.getItem(CONST.LOCAL_STORAGE_KEYS.LOCAL_SESSION) ===
          "true"
            ? true
            : false;
        userHasAuthenticated(localSession);
      }
    } catch (e) {
      if (e !== "No current user") {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    if (config.stage === "prod") {
      await Auth.signOut();
      userHasAuthenticated(false);
    } else {
      localStorage.setItem(CONST.LOCAL_STORAGE_KEYS.LOCAL_USERNAME, "");
      localStorage.setItem(CONST.LOCAL_STORAGE_KEYS.LOCAL_SESSION, false);
      userHasAuthenticated(false);
    }
    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <>
        <div className="App">
          <div className="navHeader"></div>
          <nav className="navbar">
            <NavLink className="navLogoContainer" to="/dashboard">
              <div className="navLogo">
                <img src="goyang_logo.png" alt="mini logo" />
              </div>
              <div className="navTitle">
                고양시 미니 태양광 <br></br>모니터링
              </div>
            </NavLink>
            <NavLink
              className="navItem"
              activeClassName="activeLink"
              to="/dashboard"
            >
              대시보드
            </NavLink>
            <NavLink
              className="navItem"
              activeClassName="activeLink"
              to="/household"
            >
              세대별
            </NavLink>

            {isAuthenticated ? (
              <div className="loginContainer">
                <div className="loggedInUser" to="/dashboard">
                  <Username></Username>
                </div>
                <NavLink
                  className="navItem login"
                  // activeClassName="activeLink"
                  onClick={handleLogout}
                  to="#"
                >
                  로그아웃
                </NavLink>
              </div>
            ) : (
              <NavLink
                className="navItem login"
                activeClassName="activeLink"
                to="/login"
              >
                로그인
              </NavLink>
            )}
          </nav>
          <AppContext.Provider
            value={{ isAuthenticated, userHasAuthenticated }}
          >
            <Routes />
          </AppContext.Provider>
          <div className="footer">
            <div className="footer-bar"></div>
            <div className="footer-content">
              <div className="footer-logo">
                <img src="lh.svg" alt="lh_logo logo" />
              </div>
              <div className="footer-logo">
                <img src="goyang_with_text.svg" alt="goyang_logo logo" />
              </div>
              <div className="footer-logo">
                <img src="enerdot.svg" alt="enerdot_logo logo" />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
export default App;
