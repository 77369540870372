export default Object.freeze({
  STAGE: {
    prod: "prod",
    local: "local",
  },
  URL: {
    api: "https://70fi8ipiq8.execute-api.ap-northeast-2.amazonaws.com/prod",
    localhost: "http://localhost:10001",
  },
  STRING: {
    empty: "",
  },
  ONE_K: 1000,
  LOCAL_USERS: [
    { id: "ga4lh", pw: "lh1212" },
    { id: "ga4gc", pw: "gc1212" },
    { id: "ga4ad", pw: "ad1212" },
    { id: "test", pw: "qweasd" },
  ],
  LOCAL_STORAGE_KEYS: {
    LOCAL_USERNAME: "localUsername",
    REMEMBER_ME: "rememberMe",
    LOCAL_SESSION: "localSession",
    OUTPUT_REPORT_DN_REMEMBER_ME: "outputDnRememberMe",
    CO2_REPORT_DN_REMEMBER_ME: "co2DnRememberMe",
    USERID: "userId",
  },
});
