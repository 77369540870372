import React from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import "./MonthPicker.css";
export default function MonthPicker({ startMonth, setStartMonth }) {
  return (
    <DatePicker
      selected={startMonth}
      onChange={(date) => setStartMonth(date)}
      dateFormat="yyyy.MM"
      locale={ko}
      showMonthYearPicker
    />
  );
}
