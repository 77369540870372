import React from "react";
import classes from "./SortingIcon.module.css";

export default function SortingIcon({ id, sortType, sortHandler }) {
  const convertSortTypeToSrc = (sortType) =>
    ({
      init: "sorting_init.svg",
      asc: "sorting_asc.svg",
      dsc: "sorting_dsc.svg",
    }[sortType]);

  return (
    <span className={classes.sortingIcon} id={id}>
      <img
        id={id}
        src={convertSortTypeToSrc(sortType)}
        onClick={sortHandler}
        alt={id}
      ></img>
    </span>
  );
}
