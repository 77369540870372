import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  flex: 1 0 auto;
  display: flex;
  margin: 5px;
  padding: 5px 20px;
  text-align: center;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 3mm;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export default function Card({ children }) {
  return <StyledCard>{children}</StyledCard>;
}
