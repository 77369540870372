import React from "react";
import styled from "styled-components";

const StyledCardTitle = styled.p`
  display: table-cell;
  font-weight: bolder;
  font-size: 2em;
  color: #1f1f1f;
  vertical-align: middle;
  margin-top: 100px;
`;

export default function LoginCardTitle({ children }) {
  return <StyledCardTitle>{children}</StyledCardTitle>;
}
