import React from "react";
import Card from "./Card";
import CardTitle from "./CardTitle";
import AbnormalHouseholdTable from "../table/AbnormalHouseholdTable";
import classes from "./AsGuideCard.module.css";
// import { abnormalHouseholds } from "../mockData";

export default function AsGuideCard({ abnormalHouseholds }) {
  return (
    <Card>
      <CardTitle>AS 안내</CardTitle>
      <div className={classes.asGuideContainer}>
        <div className={classes.asGuideContainerLeft}>
          <p align="left" className={classes.asDescription}>
            AS 점검 필요 세대 총{" "}
            {abnormalHouseholds ? abnormalHouseholds.length : 0} 건
          </p>
          <AbnormalHouseholdTable abnormalHouseholds={abnormalHouseholds} />
          <p align="left" className={classes.asDescription}>
            *50시간 이상 발전량이 0인 경우 AS가 필요합니다.
          </p>
        </div>
        <div className={classes.asGuideContainerRight}>
          <p className={classes.contactTitle}>미니태양광 AS 접수</p>
          <div className={classes.logoAndContactContainer}>
            <div className={classes.solarterraceLogo}>
              <img src="solarterrace.svg" alt="solarterrace logo" />
            </div>
            <div className={classes.contactContainer}>
              <p className={classes.companyName}>
                (주) 솔라테라스<br></br>
                <span className={classes.contactNumber}>문의 : 1566-3221</span>
              </p>
            </div>
          </div>

          <p className={classes.contactTitle}>시스템에러 AS 접수</p>
          <div className={classes.logoAndContactContainer}>
            <div className={classes.enerdotLogo}>
              <img src="enerdot2.svg" alt="enerdot2 logo" />
            </div>
            <div className={classes.contactContainer}>
              <p className={classes.companyName}>
                (주) 에너닷 <br></br>
                <span className={classes.contactNumber}>
                  문의 : 02-584-9602
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
}
