import React, { useState, useEffect } from "react";
import moment from "moment";

export default function Clock(props) {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    var timerID = setInterval(() => tick(), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  function tick() {
    setDate(new Date());
  }

  return (
    <>
      <div>{moment(date).format("YYYY.MM.DD")}</div>
      <div>{moment(date).format("HH:mm")}</div>
    </>
  );
}
