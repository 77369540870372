import React from "react";
import Clock from "../Clock";
import Svg from "../../Svg";
import classes from "./WeatherCard.module.css";

export default function WeatherCard({ weather }) {
  // console.log(`weather=${weather}`);

  function getDesc() {
    if (!weather) {
      return null;
    }

    if (weather.PTY === "없음" || weather.PTY === "") {
      return weather.SKY; // "맑음" "구름많음" "흐림"
    }

    return weather.PTY; // "없음" "비" "비/눈" "눈" "소나기" ""
  }

  function convertWeatherName() {
    if (!weather) {
      return null;
    }

    let sky = weather.SKY;
    let pty = weather.PTY;

    if (pty === "없음" || pty === "") {
      if (sky === "맑음") return "sunny";
      if (sky === "구름많음") return "cloudy";
      if (sky === "흐림") return "cloudy";
    }

    if (pty === "비") {
      if (sky === "맑음") return "rain_sunny";
      if (sky === "구름많음") return "rain_cloudy";
      if (sky === "흐림") return "rain_cloudy";
    }

    if (pty === "비/눈") {
      if (sky === "맑음") return "rain_snow_sunny";
      if (sky === "구름많음") return "rain_snow_cloudy";
      if (sky === "흐림") return "rain_snow_cloudy";
    }

    if (pty === "눈") {
      if (sky === "맑음") return "snow_sunny";
      if (sky === "구름많음") return "snow_cloudy";
      if (sky === "흐림") return "snow_cloudy";
    }

    if (pty === "소나기") {
      if (sky === "맑음") return "shower_sunny";
      if (sky === "구름많음") return "rain_shower";
      if (sky === "흐림") return "rain_shower";
    }

    return null;
  }

  return (
    <div className={classes.weatherCard}>
      <div className={classes.time}>
        <Clock></Clock>
      </div>

      <div className={classes.weatherIcon}>
        {weather ? <Svg name={convertWeatherName()} /> : null}
      </div>

      <div className={classes.weatherDesc}>
        {weather ? (
          <div>
            {weather.T1H}°C {getDesc()}
          </div>
        ) : null}
      </div>
    </div>
  );
}
