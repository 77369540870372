import React from "react";
import Card from "./Card";
import CardTitle from "./CardTitle";
import BarChart from "../chart/BarChart";
import DirectionOutputTable from "../table/DirectionOutputTable";
import classes from "./TodayDirectionOutputCard.module.css";

export default function TodayDirectionOutputCard({ directionSortedOutput }) {
  let minOutput = !directionSortedOutput
    ? 0
    : Math.min.apply(
        null,
        directionSortedOutput.map((d) => d.avgAccOutput).flat(1)
      ) - 1;
  minOutput = minOutput < 0 ? 0 : minOutput;

  let maxOutput = !directionSortedOutput
    ? 0
    : Math.max.apply(
        null,
        directionSortedOutput.map((d) => d.avgAccOutput).flat(1)
      ) + 0.3;

  return (
    <Card>
      <CardTitle>향동 A4지구 금일 누적 발전량 (kWh)</CardTitle>
      <div className={classes.directionOutputLegendContainer}>
        <div className={classes.directionLegend}>
          <span className={classes.eastSouthDot}></span>
          <span> 동남 방위</span>
        </div>
        <div className={classes.directionLegend}>
          <span className={classes.southWestDot}></span>
          <span> 남서 방위</span>
        </div>
      </div>
      <div className={classes.todayAccChart}>
        <div className={classes.barChartContiner}>
          {directionSortedOutput ? (
            <BarChart
              width={500}
              height={170}
              datasets={[
                {
                  label: "동남 방위",
                  backgroundColor: "#22D3BD",
                  data: directionSortedOutput
                    .filter((d) => d.direction === "ES")
                    .map((d) => d.avgAccOutput),
                  barPercentage: 0.7,
                },
                {
                  label: "남서 방위",
                  backgroundColor: "#4A80FC",
                  data: directionSortedOutput
                    .filter((d) => d.direction === "SW")
                    .map((d) => d.avgAccOutput),
                  barPercentage: 0.7,
                },
              ]}
              labels={["최고층", "고층", "중층", "저층", "최저층"]}
              chartId="directionOutputChart"
              options={{
                plugins: {
                  // Change options for ALL labels of THIS CHART
                  datalabels: {
                    color: "#000000",
                    anchor: "end",
                    clamp: true,
                    align: "end",
                  },
                },
                tooltips: {
                  enabled: true,
                },
                //Customize chart options
                scales: {
                  yAxes: [
                    {
                      display: false,
                      ticks: {
                        suggestedMin: minOutput,
                        // Math.min.apply(null, data.map((d) => d.output).flat(1)) -
                        // 1,
                        // minimum will be 0, unless there is a lower value.
                        // OR //
                        // beginAtZero: true, // minimum value will be 0.
                        suggestedMax: maxOutput,

                        // Math.max.apply(null, data.map((d) => d.output).flat(1)) +
                        // 1,
                      },
                      scaleLabel: {
                        display: false,
                        labelString: "발전량",
                        fontSize: 8,
                      },
                      gridLines: {
                        display: false,
                        offsetGridLines: false,
                        drawOnChartArea: false,
                        drawTicks: false,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      // barThickness: 3,
                      // maxBarThickness: 3,

                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "시간",
                        fontSize: 8,
                      },
                      gridLines: {
                        display: false,
                        offsetGridLines: false,
                        drawOnChartArea: false,
                        // drawTicks: false,
                      },
                    },
                  ],
                },
              }}
            />
          ) : null}
        </div>
        <div className={classes.todayAccDong}>402동</div>
      </div>
      <div className={classes.todayAccTable}>
        <DirectionOutputTable directionSortedOutput={directionSortedOutput} />
        <p align="left" className={classes.levelDescription}>
          *1~3층(최저층)/4~9(저층)/10~15층(중층)/16~21층(고층)/22~24층(최고층)
        </p>
      </div>
    </Card>
  );
}
