import React from "react";
import Card from "./Card";
import CardTitle from "./CardTitle";
import Measurement from "../Measurement";
import BarChart from "../chart/BarChart";
import classes from "./TodayHourlyOutputCard.module.css";
import HouseholdNumber from "../HouseholdNumber";

export default function TodayHourlyOutputCard({ hourlyOuputs }) {
  // console.log(`todayhour=${JSON.stringify(hourlyOuputs)}`);

  let minOutput = !hourlyOuputs
    ? 0
    : Math.min.apply(null, hourlyOuputs.map((d) => d.output).flat(1)) - 1;
  minOutput = minOutput < 0 ? 0 : minOutput;

  let maxOutput = !hourlyOuputs
    ? 0
    : Math.max.apply(null, hourlyOuputs.map((d) => d.output).flat(1)) + 1;

  return (
    <Card>
      <div className={classes.todayTotalOutputTitleBar}>
        <CardTitle>
          오늘 총 발전량 <HouseholdNumber>- 64 세대 기준</HouseholdNumber>
        </CardTitle>
        <Measurement
          value={
            hourlyOuputs
              ? hourlyOuputs.reduce((prev, cur) => prev + cur.output, 0)
              : 0
          }
          unit={"kWh"}
        ></Measurement>
      </div>
      <div className={classes.todayTotalOutputChartContainer}>
        <p className={classes.yAxisLabel}>발전량</p>
        <div className={classes.todayTotalOutputChartInnerContainer}>
          {hourlyOuputs ? (
            <BarChart
              width={580}
              height={160}
              datasets={[
                {
                  barPercentage: 0.7,
                  backgroundColor: "#79DCF8",
                  data: hourlyOuputs.map((ho) => ho.output),
                },
              ]}
              labels={hourlyOuputs.map((ho) => ho.hour)}
              chartId="todayTotalOutputChart"
              options={{
                plugins: {
                  // Change options for ALL labels of THIS CHART
                  datalabels: {
                    labels: {
                      title: null,
                    },
                  },
                },
                tooltips: {
                  enabled: true,
                  displayColors: false,
                  callbacks: {
                    title: function (tooltipItem, data) {
                      return "";
                    },

                    label: function (tooltipItem, data) {
                      return (
                        data["labels"][tooltipItem["index"]] +
                        "시" +
                        ": " +
                        data["datasets"][0]["data"][tooltipItem["index"]] +
                        "kWh"
                      );
                    },
                    labelColor: function (tooltipItem, chart) {
                      return {
                        borderColor: "rgb(0, 0, 0)",
                        backgroundColor: "rgb(0, 0, 0)",
                      };
                    },
                    labelTextColor: function (tooltipItem, chart) {
                      return "#ffffff";
                    },
                  },
                },
                //Customize chart options
                scales: {
                  yAxes: [
                    {
                      display: true,
                      ticks: {
                        suggestedMin: minOutput, // minimum will be 0, unless there is a lower value.
                        // OR //
                        // beginAtZero: true, // minimum value will be 0.
                        suggestedMax: maxOutput,
                        fontSize: 12,
                      },
                      scaleLabel: {
                        display: false,
                        labelString: "발전량",
                        fontSize: 16,
                      },
                      gridLines: {
                        // display: false,
                        // offsetGridLines: false,
                        // drawOnChartArea: false,
                        // drawTicks: false,
                      },
                    },
                  ],
                  xAxes: [
                    {
                      ticks: {
                        fontSize: 12,
                      },
                      // barThickness: 3,
                      // maxBarThickness: 3,
                      // barPercentage: 0.7,
                      display: true,
                      scaleLabel: {
                        display: false,
                        labelString: "시간",
                        fontSize: 16,
                      },
                      gridLines: {
                        display: false,
                        offsetGridLines: false,
                        drawOnChartArea: false,
                        // drawTicks: false,
                      },
                    },
                  ],
                },
              }}
            />
          ) : null}
          <p className={classes.xAxisLabel}>시간</p>
        </div>
      </div>
    </Card>
  );
}
