import React, { useState } from "react";
import { dn } from "../libs/awsLib";

import Modal from "../components/Modal";
import MonthPicker from "../components/MonthPicker";
import YearPicker from "../components/YearPicker";
import { useFormFields } from "../libs/hooksLib";
import { onError } from "../libs/errorLib";
import ExcelIcon from "../components/ExcelIcon";
import moment from "moment";
import CONST from "../Const";
import "./ModalPage.css";

const MONTHLY_OUTPUT_REPORT_PREFIX = "고양시_미니태양광_발전량_";
const MONTHLY_CO2_REPORT_PREFIX = "고양시_미니태양광_탄소배출_저감량_";
const XLSX_POSTFIX = ".xlsx";

function ModalPage() {
  const [modalVisible, setModalVisible] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  const [startMonth, setStartMonth] = useState(new Date());
  const [startYear, setStartYear] = useState(new Date());
  const [fields, handleFieldChange] = useFormFields({
    outputDnRememberMe:
      localStorage.getItem(
        CONST.LOCAL_STORAGE_KEYS.OUTPUT_REPORT_DN_REMEMBER_ME
      ) === "true"
        ? true
        : false,
    co2DnRememberMe:
      localStorage.getItem(
        CONST.LOCAL_STORAGE_KEYS.CO2_REPORT_DN_REMEMBER_ME
      ) === "true"
        ? true
        : false,
  });

  const openModal = () => {
    setModalVisible(true);
  };
  const closeModal = () => {
    setModalVisible(false);
    localStorage.setItem(
      CONST.LOCAL_STORAGE_KEYS.OUTPUT_REPORT_DN_REMEMBER_ME,
      fields.outputDnRememberMe
    );
    localStorage.setItem(
      CONST.LOCAL_STORAGE_KEYS.CO2_REPORT_DN_REMEMBER_ME,
      fields.co2DnRememberMe
    );
  };

  async function handleSubmit(event) {
    event.preventDefault();
    // setIsLoading(true);

    try {
      console.log(moment(startMonth).format("YYYYMM"));
      console.log(moment(startYear).format("YYYY"));
      const yyyymm = moment(startMonth).format("YYYYMM");
      const yyyy = moment(startYear).format("YYYY");

      if (fields.outputDnRememberMe) {
        setTimeout(() => {
          dn(
            "/report/output",
            MONTHLY_OUTPUT_REPORT_PREFIX + yyyymm + XLSX_POSTFIX,
            {
              yyyymm: yyyymm,
            }
          );
        }, 1000);
      }

      if (fields.co2DnRememberMe) {
        setTimeout(() => {
          dn("/report/co2", MONTHLY_CO2_REPORT_PREFIX + yyyy + XLSX_POSTFIX, {
            yyyy: yyyy,
          });
        }, 2000);
      }
    } catch (e) {
      onError(e);
      // setIsLoading(false);
    }
  }

  return (
    <>
      <button className="modal-btn" onClick={openModal}>
        <ExcelIcon>미니태양광 발전 정보</ExcelIcon>
      </button>
      {modalVisible && (
        <Modal
          visible={modalVisible}
          closable={true}
          maskClosable={true}
          onClose={closeModal}
        >
          <div className="modal-container">
            <div className="modal-title">
              <p>엑셀 다운로드</p>
            </div>
            <div className="modal-content">
              <div className="monthly-output-info-container">
                <div className="outputDnCheck-container">
                  <input
                    id="outputDnRememberMe"
                    type="checkbox"
                    checked={fields.outputDnRememberMe}
                    onChange={handleFieldChange}
                  />
                  <label
                    className="download-checkbox-label"
                    htmlFor="outputDnRememberMe"
                  >
                    발전량 정보
                  </label>
                </div>
                <MonthPicker
                  startMonth={startMonth}
                  setStartMonth={setStartMonth}
                />
              </div>
              <div className="yearly-co2-info-container">
                <div className="outputDnCheck-container">
                  <input
                    id="co2DnRememberMe"
                    type="checkbox"
                    checked={fields.co2DnRememberMe}
                    onChange={handleFieldChange}
                  />
                  <label
                    className="download-checkbox-label"
                    htmlFor="co2DnRememberMe"
                  >
                    탄소배출
                  </label>
                </div>
                <YearPicker startYear={startYear} setStartYear={setStartYear} />
              </div>
              <button
                className="download-btn"
                type="submit"
                onClick={handleSubmit}
              >
                다운로드
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}

export default ModalPage;
