import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
  /* overflow: auto; */
  width: 1200px;
  height: 700px;
  margin: 30px 0px 30px 0px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  border-radius: 3mm;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
`;

export default function LoginCard({ children }) {
  return <StyledCard>{children}</StyledCard>;
}
