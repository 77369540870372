import { API, Storage } from "aws-amplify";
import config from "../config";
import axios from "axios";
import CONST from "../Const";

export async function apiGet(apiName, path, queryStringParameters) {
  if (config.stage === CONST.STAGE.prod) {
    return await API.get(apiName, path, {
      headers: { "x-api-key": config.apiGateway.API_KEY },
      queryStringParameters: queryStringParameters || {},
    });
  } else {
    let queryString = generateQueryString(queryStringParameters);
    return (await axios.get(CONST.URL.localhost + path + queryString)).data;
  }
}

export async function dn(path, filename, queryStringParameters) {
  const queryString = generateQueryString(queryStringParameters);
  let url = CONST.URL.localhost + path + queryString;

  if (config.stage === CONST.STAGE.prod) {
    if (path === "/report/output") {
      url =
        "https://minisolar.s3.ap-northeast-2.amazonaws.com/minisolar-api/report/prod/%EA%B3%A0%EC%96%91%EC%8B%9C_%EB%AF%B8%EB%8B%88%ED%83%9C%EC%96%91%EA%B4%91_%EB%B0%9C%EC%A0%84%EB%9F%89_" +
        queryStringParameters.yyyymm +
        ".xlsx";
    } else {
      url =
        "https://minisolar.s3.ap-northeast-2.amazonaws.com/minisolar-api/report/prod/%EA%B3%A0%EC%96%91%EC%8B%9C_%EB%AF%B8%EB%8B%88%ED%83%9C%EC%96%91%EA%B4%91_%ED%83%84%EC%86%8C%EB%B0%B0%EC%B6%9C_%EC%A0%80%EA%B0%90%EB%9F%89_" +
        queryStringParameters.yyyy +
        ".xlsx";
    }
  }

  console.log(`url=${url}`);
  axios({
    url: url,
    method: "GET",
    responseType: "blob", // important
  }).then((response) => {
    if (response.headers["content-type"] === "text/html; charset=utf-8") {
      console.log("Skip download");
      return;
    }
    var blob = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    if (window.navigator.msSaveBlob) {
      // window.setTimeout(() => {
      window.navigator.msSaveBlob(blob, filename);
      // }, 1000);
    } else {
      console.log(JSON.stringify(response));
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
    }
  });
}

function generateQueryString(queryStringParameters) {
  if (!queryStringParameters) {
    return CONST.STRING.empty;
  }

  let queryArr = [];
  for (const [key, value] of Object.entries(queryStringParameters)) {
    queryArr.push(`${key}=${value}`);
  }

  return queryArr.length > 0 ? "?" + queryArr.join("&") : "";
}
