import React from "react";
import styled from "styled-components";

const StyledCardTitle = styled.p`
  align-self: flex-start;
  font-weight: bolder;
  font-size: 1.1em;
  color: #1f1f1f;
  text-align: left;
  // margin-left: 10px;
  margin-bottom: 10px;
`;

export default function CardTitle({ children }) {
  return <StyledCardTitle>{children}</StyledCardTitle>;
}
