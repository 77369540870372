import React from "react";
import CountUp from "react-countup";
import MeasureText from "./MeasureText";
import UnitText from "./UnitText";
import classes from "./Measurement.module.css";

export default function Measurement({ value, unit, decimals }) {
  return (
    <div className={classes.measurement}>
      <MeasureText>
        {value ? (
          <CountUp
            end={typeof value === "number" ? value : Number(value)}
            duration={3}
            decimals={decimals || 2}
            separator=","
          />
        ) : (
          0
        )}
      </MeasureText>
      <UnitText>{unit}</UnitText>
    </div>
  );
}
