import React from "react";
import styled from "styled-components";

const StyledUnitText = styled.p`
  font-weight: normal;
  font-size: 1.5em;
  color: #1f1f1f;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
`;

export default function UnitText({ children }) {
  return <StyledUnitText>{children}</StyledUnitText>;
}
