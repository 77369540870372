import React from "react";
import SortingIcon from "../SortingIcon";
import "./HouseholdTable.css";

const STATUS_HEADER = "status";
const HOUSEHOLD_HEADER = "householdId";
const DIRECTION_HEADER = "direction";
const ACC_OUTPUT_HEADER = "dailySum";
const GENERATION_HOUR_HEADER = "outputHours";
export default function HouseholdTable({
  initData,
  householdOutputs,
  setHouseholdOutputs,
  sortFactors,
  setSortFactors,
}) {
  // console.log(JSON.stringify(householdOutputs, null, 2));
  function getSortType(sortField) {
    let idx = sortFactors.findIndex(
      (sortFactor) => sortFactor.sortField === sortField
    );
    let sortType =
      sortFactors[idx].sortType === -1
        ? "dsc"
        : sortFactors[idx].sortType === 1
        ? "asc"
        : "init";

    return sortType;
  }
  const sortHandler = (e) => {
    console.log(e.target.id);
    let sortField = e.target.id;
    let foundIdx = sortFactors.findIndex((sf) => sf.sortField === e.target.id);
    console.log(`sortFactors=${sortFactors}, foundIdx=${foundIdx}`);
    let prevSortType = sortFactors[foundIdx].sortType;
    let curSortType = prevSortType === 0 ? 1 : prevSortType === 1 ? -1 : 0;
    let curSortFactor = { sortField: sortField, sortType: curSortType };

    if (curSortType === 0) {
      sortFactors.splice(foundIdx, 1);
      sortFactors.push(curSortFactor);
    } else {
      sortFactors.splice(foundIdx, 1);
      let insertIdx = sortFactors.findIndex((sf) => sf.sortType === 0);
      if (insertIdx === -1) {
        sortFactors.push(curSortFactor);
      } else {
        sortFactors.splice(insertIdx, 0, curSortFactor);
      }
    }

    console.log(JSON.stringify(sortFactors, null, 2));
    setSortFactors([...sortFactors]);

    let noNeedSort = sortFactors
      .filter((s) => s.sortField !== STATUS_HEADER)
      .every((s) => s.sortType === 0);

    console.log(`noNeedSort=${noNeedSort}`);

    let sorted = noNeedSort
      ? initData
      : [...initData].sort((a, b) => {
          return sortFactors.reduce((prev, cur) => {
            let sortType = cur.sortType;
            let sortField = cur.sortField;
            console.log(
              `sortType=${sortType}, sortField=${sortField},b=${JSON.stringify(
                b
              )}`
            );
            let condition;
            if (sortType === 0 || sortType === 1) {
              if (typeof b[sortField] === "string") {
                condition =
                  a[sortField] < b[sortField]
                    ? 1
                    : a[sortField] > b[sortField]
                    ? -1
                    : 0;
              } else {
                condition = a[sortField] - b[sortField];
              }
            } else {
              if (typeof b[sortField] === "string") {
                condition =
                  a[sortField] > b[sortField]
                    ? 1
                    : a[sortField] < b[sortField]
                    ? -1
                    : 0;
              } else {
                condition = b[sortField] - a[sortField];
              }
            }
            // console.log(
            //   `prev=${prev}, condition=${condition}, result=${prev || condition}`
            // );
            return prev || condition;
          }, 0);
        });

    setHouseholdOutputs([...sorted]);
    // setChange(!change);
  };

  return (
    <div className="households-table">
      <table>
        <thead>
          <tr key="header">
            <th id={STATUS_HEADER}>상태</th>
            <th
              className="fixedWidthHeader"
              id={HOUSEHOLD_HEADER}
              onClick={sortHandler}
            >
              설치 세대
              <SortingIcon
                id={HOUSEHOLD_HEADER}
                sortType={getSortType(HOUSEHOLD_HEADER)}
                sortHandler={sortHandler}
              />
            </th>
            <th id={DIRECTION_HEADER} onClick={sortHandler}>
              설치 방위
              <SortingIcon
                id={DIRECTION_HEADER}
                sortType={getSortType(DIRECTION_HEADER)}
                sortHandler={sortHandler}
              />
            </th>
            <th id={ACC_OUTPUT_HEADER} onClick={sortHandler}>
              누적 발전량
              <SortingIcon
                id={ACC_OUTPUT_HEADER}
                sortType={getSortType(ACC_OUTPUT_HEADER)}
                sortHandler={sortHandler}
              />
            </th>
            <th id={GENERATION_HOUR_HEADER} onClick={sortHandler}>
              발전 시간
              <SortingIcon
                id={GENERATION_HOUR_HEADER}
                sortType={getSortType(GENERATION_HOUR_HEADER)}
                sortHandler={sortHandler}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {householdOutputs.map((householdOutput) => {
            return (
              <tr key={householdOutput.householdId}>
                <td key="status">
                  {householdOutput.status === 0 ? (
                    <div className="normalDot"></div>
                  ) : (
                    <div className="asDot"></div>
                  )}
                </td>
                <td key="addr">
                  {householdOutput.dong}동 {householdOutput.ho}호
                </td>
                <td key="direction">{householdOutput.direction}</td>
                <td key="dailySum" className="measure-cell">
                  {householdOutput.dailySum || 0} Wh
                </td>
                <td key="outputHours" className="measure-cell">
                  {householdOutput.outputHours || 0} 시간
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
