import React from "react";
import Card from "./Card";
import CardTitle from "./CardTitle";
import Measurement from "../Measurement";
import HouseholdNumber from "../HouseholdNumber";

export default function MonthlyAccOutputCard({ monthlyOutput }) {
  // console.log(`MonthlyAccOutputCard data=${monthlyOutput}`);
  return (
    <Card>
      <CardTitle>
        이번 달 누적 발전량<br></br>{" "}
        <HouseholdNumber>- 64 세대 기준</HouseholdNumber>
      </CardTitle>
      <Measurement value={monthlyOutput} unit={"kWh"}></Measurement>
    </Card>
  );
}
