import React, { Component } from "react";
import Chart from "chart.js";
import classes from "./DonutChart.module.css";
//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "'PT Sans', sans-serif";
Chart.defaults.global.legend.display = false;
//--Chart Style Options--//
let pieChart;
export default class DonutChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { data, labels } = this.props;

    if (typeof pieChart !== "undefined") pieChart.destroy();

    pieChart = new Chart(myChartRef, {
      type: "doughnut",
      data: {
        //Bring in data

        datasets: [
          {
            data: data,
            backgroundColor: ["#79DCF8", "#FE9000"],
          },
        ],
        labels:
          labels.length === data.length
            ? labels
            : new Array(data.length).fill("Data"),
      },
      options: {
        //Customize chart options
        layout: {
          padding: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
          margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
          },
        },
      },
    });
  };

  render() {
    return (
      <div className={classes.graphContainer}>
        {/* <div> */}
        <canvas
          id={this.props.chartId}
          ref={this.chartRef}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
