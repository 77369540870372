import React from "react";
import "./DirectionOutputTable.css";

const DONG_HEADER = "동";
const FLOOR_HEADER = "층";
const DIRECTION_HEADER = "방위";
const AVG_ACC_OUTPUT_HEADER = "세대 평균 누적 발전량";
const AVG_GENERATION_HOUR_HEADER = "세대 평균 발전시간";
export default function DirectionOutputTable({ directionSortedOutput }) {
  return (
    <div className="today-acc">
      <table>
        <thead>
          <tr key="header">
            <th id={DONG_HEADER}>{DONG_HEADER}</th>
            <th id={FLOOR_HEADER}>{FLOOR_HEADER}</th>
            <th id={DIRECTION_HEADER}>{DIRECTION_HEADER}</th>
            <th id={AVG_ACC_OUTPUT_HEADER}>{AVG_ACC_OUTPUT_HEADER}</th>
            <th id={AVG_GENERATION_HOUR_HEADER}>
              {AVG_GENERATION_HOUR_HEADER}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="10">402</td>
            <td rowSpan="2">최고층</td>
            <td>동남</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[0].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[0].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td>남서</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[1].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[1].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td rowSpan="2">고층</td>
            <td>동남</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[2].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[2].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td>남서</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[3].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[3].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td rowSpan="2">중층</td>
            <td>동남</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[4].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[4].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td>남서</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[5].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[5].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td rowSpan="2">저층</td>
            <td>동남</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[6].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[6].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td>남서</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[7].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[7].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td rowSpan="2">최저층</td>
            <td>동남</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[8].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[8].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
          <tr>
            <td>남서</td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[9].avgAccOutput.toFixed(2)
                : 0}{" "}
              kWh
            </td>
            <td className="measure-cell">
              {directionSortedOutput
                ? directionSortedOutput[9].avgOutputTime.toFixed(1)
                : 0}{" "}
              시간
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
