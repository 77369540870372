import React, { Component } from "react";
import Chart from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import classes from "./BarChart.module.css";
//--Chart Style Options--//
Chart.defaults.global.defaultFontFamily = "Arial";
Chart.defaults.global.defaultFontSize = 14;
Chart.defaults.global.legend.display = false;
// Chart.defaults.scale.gridLines.display = false;
//--Chart Style Options--//

export default class BarChart extends Component {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");
    const { datasets, labels, options } = this.props;
    let barChart;
    if (typeof barChart !== "undefined") barChart.destroy();

    barChart = new Chart(myChartRef, {
      type: "bar",
      data: {
        //Bring in data
        labels: labels,
        datasets: datasets,
      },
      options: options,
      plugins: [ChartDataLabels],
    });
  };

  render() {
    return (
      <div className={classes.graphContainer}>
        {/* <div> */}
        <canvas
          id={this.props.chartId}
          ref={this.chartRef}
          width={this.props.width}
          height={this.props.height}
        />
      </div>
    );
  }
}
