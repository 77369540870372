import React from "react";
import Card from "./Card";
import CardTitle from "./CardTitle";
import Measurement from "../Measurement";
import HouseholdNumber from "../HouseholdNumber";

export default function TreePlantingCard({ plantingEffect }) {
  return (
    <Card>
      <CardTitle>
        이번 달 나무 심은 효과<br></br>
        <HouseholdNumber>- 498 세대 기준</HouseholdNumber>
      </CardTitle>
      <Measurement value={plantingEffect} unit={"그루"} />
    </Card>
  );
}
