import React from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import "react-datepicker/dist/react-datepicker.css";
import "./MonthPicker.css";
export default function MonthPicker({ startYear, setStartYear }) {
  return (
    <DatePicker
      selected={startYear}
      onChange={(date) => setStartYear(date)}
      dateFormat="yyyy"
      locale={ko}
      showYearPicker
    />
  );
}
