const local = {};

const prod = {
  apiGateway: {
    REGION: "ap-northeast-2",
    URL: "https://70fi8ipiq8.execute-api.ap-northeast-2.amazonaws.com/prod",
    API_KEY: process.env.REACT_APP_API_KEY,
  },
  cognito: {
    REGION: "ap-northeast-2",
    USER_POOL_ID: "ap-northeast-2_2lJOgHHgB",
    APP_CLIENT_ID: "3jain78l106u1a1he93dup2ocb",
    IDENTITY_POOL_ID: "ap-northeast-2:ad8a76b0-ab52-408c-8657-986a3aa219d2",
  },
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === "prod" ? prod : local;

export default {
  // Add common config values here
  stage: process.env.REACT_APP_STAGE,
  api_server: process.env.REACT_APP_API_SERVER,
  ...config,
};
