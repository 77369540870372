import React, { useState, useEffect } from "react";
import { apiGet } from "../libs/awsLib";
import { onError } from "../libs/errorLib";
import HouseholdTable from "../components/table/HouseholdTable";
import OverallStatusCard from "../components/card/OverallStatusCard";
import ModalPage from "../components/ModalPage";

import "./Household.css";
const FETCH_SIZE = 23;
const STATUS_HEADER = "status";
const HOUSEHOLD_HEADER = "householdId";
const DIRECTION_HEADER = "direction";
const ACC_OUTPUT_HEADER = "dailySum";
const GENERATION_HOUR_HEADER = "outputHours";

const Household = () => {
  const [initData, setInitData] = useState([]);
  const [householdOutputs, setHouseholdOutputs] = useState([]);
  const [sortFactors, setSortFactors] = useState([
    { sortField: STATUS_HEADER, sortType: -1 }, // -1: 내림차순, 0: 정렬없음, 1: 오름차순
    { sortField: HOUSEHOLD_HEADER, sortType: 0 },
    { sortField: DIRECTION_HEADER, sortType: 0 },
    { sortField: ACC_OUTPUT_HEADER, sortType: 0 },
    { sortField: GENERATION_HOUR_HEADER, sortType: 0 },
  ]);
  // const [seen, setSeen] = useState(false);
  // const [change, setChange] = useState(true);

  useEffect(() => {
    async function onLoad() {
      try {
        const householdOutputs = await apiGet(
          "minisolar",
          `/households/todayOutputs`
        );
        if (!householdOutputs) {
          return;
        }

        setInitData([...householdOutputs]);
        setHouseholdOutputs([...householdOutputs]);
      } catch (e) {
        onError(e);
      }
    }

    onLoad();
    const intervalId = setInterval(() => onLoad(), 30000);
    return () => {
      // console.log(`intervalId=${intervalId}`);
      clearInterval(intervalId);
    };
  }, []);

  // const togglePop = () => {
  //   setSeen(!seen);
  // };

  // console.log(householdOutputs);

  const getHouseholdStatusOutput = () => {
    return {
      normal: householdOutputs.filter((ho) => ho.status === 0).length,
      abnormal: householdOutputs.filter((ho) => ho.status !== 0).length,
    };
  };

  return (
    <div className="container">
      {/* <img src="image.png" alt="mini logo" /> */}
      <div className="left-container">
        <OverallStatusCard householdStatus={getHouseholdStatusOutput()} />
        <ModalPage />
      </div>
      <div className="table-container">
        <HouseholdTable
          initData={initData}
          householdOutputs={householdOutputs.slice(0, FETCH_SIZE)}
          setHouseholdOutputs={setHouseholdOutputs}
          pageNumb={0}
          sortFactors={sortFactors}
          setSortFactors={setSortFactors}
          // sortHandler={sortHandler}
          // change={change}
        />
        <HouseholdTable
          initData={initData}
          householdOutputs={householdOutputs.slice(FETCH_SIZE, FETCH_SIZE * 2)}
          setHouseholdOutputs={setHouseholdOutputs}
          pageNumb={1}
          sortFactors={sortFactors}
          setSortFactors={setSortFactors}
          // sortHandler={sortHandler}
          // change={change}
        />
        <HouseholdTable
          initData={initData}
          householdOutputs={householdOutputs.slice(
            FETCH_SIZE * 2,
            FETCH_SIZE * 3
          )}
          setHouseholdOutputs={setHouseholdOutputs}
          pageNumb={2}
          sortFactors={sortFactors}
          setSortFactors={setSortFactors}
          // sortHandler={sortHandler}
          // change={change}
        />
      </div>
    </div>
  );
};

export default Household;
